import React, { useEffect } from 'react';
import Card from '@mui/material/Card';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  updateActivePoint,
  transformToPoint
} from 'redux/slice/mapControllerSlice';

import HeaderTab from 'components/detailCard/HeaderTab';
import TabPanel from 'components/detailCard/TabPanel';
import BuoyDescription from 'components/detailCard/BuoyDescription';
import BuoyInfo from 'components/detailCard/BuoyInfo';

// =============================================================================
interface DetailCardProps {
  transforms: string[];
  cardData: {};
  disabled: boolean[];
  style?: React.CSSProperties;
}

export default function DetailCard(props: DetailCardProps) {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);

  const transform = useAppSelector((state) => state.mapController.transform);
  const activePoint = useAppSelector(
    (state) => state.mapController.activePoint
  );

  const headers = Object.keys(props.cardData);
  const bodies = Object.values(props.cardData);

  useEffect(() => {
    console.log(transform);
    const newValue = headers.indexOf(activePoint);
    if (newValue != -1) setValue(newValue);
  }, [activePoint]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(updateActivePoint(headers[newValue]));
    dispatch(transformToPoint(props.transforms[newValue]));
  };

  return (
    <div>
      <Card sx={props.style}>
        <HeaderTab
          value={value}
          onChange={handleChange}
          headers={headers}
          disabled={props.disabled}
        />

        <div>
          {bodies.map((obj: any, index) => (
            <TabPanel value={value} index={index} key={index}>
              <BuoyDescription description={obj.description} />
              {obj.data && obj.data.length > 0 ? (
                <div>
                  <BuoyInfo infos={obj.data} />
                </div>
              ) : (
                <div />
              )}
            </TabPanel>
          ))}
        </div>
      </Card>
    </div>
  );
}
