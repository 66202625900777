import Typography from '@mui/material/Typography';
import Container from 'components/common/Container';
import HeadingBody from 'components/text/HeadingBody';

import useViewportRatio from 'utils/useViewportRatio';
import useWidth from 'utils/useWidth';

import BuoyImageInfo from './BuoyImageInfo';
import WaveLine from 'components/waveLine/WaveLine';
import custom_palettes from 'theme/custom_palettes';

// =============================================================================
const buoy_assistive_text = 'Two-tiered modular design';

// =============================================================================
interface IntroProps {
  title: string;
  description: string;
  buoyDesignDescription?: {
    peripheralMoonpool?: string | null;
    peripheralModules?: string | null;
    solarPanel?: string | null;
    sensors?: string | null;
  } | null;
}

export default function Intro(props: IntroProps) {
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const ratio = useViewportRatio(standard);
  const width = useWidth();

  return (
    <Container
      style={{
        backgroundColor: custom_palettes.green[50],
        marginLeft: 0,
        marginRight: 0,
        width: '100%'
      }}
    >
      <div style={{ backgroundColor: custom_palettes.white.main }}>
        <WaveLine style={{ fill: custom_palettes.green[50] }} />
      </div>

      <Container
        style={{
          display: width === 'mobile' ? undefined : 'flex',
          backgroundColor: custom_palettes.green[50]
        }}
      >
        <HeadingBody
          title={props.title}
          titleVariant={width === 'mobile' ? 'h3' : 'h2'}
          text={props.description}
          textVariant='body_regular'
          textWidth={width === 'mobile' ? undefined : 497}
          style={{
            textAlign: width === 'mobile' ? 'center' : undefined,
            marginTop: width === 'mobile' ? undefined : 96
          }}
        />

        {width === 'mobile' ? (
          <div style={{ height: 56 }} />
        ) : (
          <div style={{ flexGrow: 1 }} />
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column' as const,
            justifyContent: 'center' as const,
            alignItems: 'center' as const,
            textAlign: 'center' as const
          }}
        >
          {width === 'mobile' ? (
            <Typography variant='body_large_strong' color='blue.900'>
              {buoy_assistive_text}
            </Typography>
          ) : undefined}

          <BuoyImageInfo
            width={width}
            ratio={ratio}
            descriptions={props.buoyDesignDescription}
          />

          {width === 'mobile' ? undefined : (
            <Typography variant='body_large_strong' color='blue.900'>
              {buoy_assistive_text}
            </Typography>
          )}
        </div>
      </Container>
      {
        <div style={{ backgroundColor: custom_palettes.green[50] }}>
          <WaveLine style={{ fill: custom_palettes.white.main }} />
        </div>
      }
    </Container>
  );
}
