import api from 'utils/api';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import { CONSTANTS } from 'constant';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setGraphData,
  setInterval,
  setIsLoading,
  setPrevEndTime,
  setPrevStartTime,
  setBoxData,
  setScatterData,
  setActiveGraph
} from 'redux/slice/graphControllerSlice';
import { processBoxData } from './data';

export const getAppVersion = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const dateStr = `${now.getFullYear()}${month < 10 ? '0' + month : month}${
    date < 10 ? '0' + date : date
  }`;
  const BUILD_NUM = import.meta.env.VITE_BUILD_NUM || '';

  return BUILD_NUM ? ` v2.0-(${dateStr}-${BUILD_NUM})` : '';
};

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const myChartJsTooltipHandler = (context: any, htmlGenerator: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  const yAlign = tooltip.yAlign;
  const xAlign = tooltip.xAlign;

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
  // if (tooltipModel.yAlign || tooltipModel.xAlign) {
  tooltipEl.classList.add(yAlign);
  tooltipEl.classList.add(xAlign === 'center' ? 'left' : xAlign);

  // Set Text
  const body = htmlGenerator(tooltip);
  tooltipEl.innerHTML = body;

  // Tooltip height and width
  const { height, width } = tooltipEl.getBoundingClientRect();
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Carets
  const caretY = tooltip.caretY;
  const caretX = tooltip.caretX;

  // Final coordinates
  let top = positionY + caretY - height;
  let left = positionX + caretX - width / 2;
  let space = 8; // The caret plus one pixle for some space, you can increase it.

  // yAlign could be: `top`, `bottom`, `center`
  if (yAlign === 'top') {
    top += height + space;
  } else if (yAlign === 'center') {
    top += height / 2;
  } else if (yAlign === 'bottom') {
    top -= space;
  }

  // xAlign could be: `left`, `center`, `right`
  if (xAlign === 'left') {
    left += width / 2 - space / 2;
    if (yAlign === 'center') {
      left += space * 2;
    }
  } else if (xAlign === 'right') {
    left -= width / 2;
    if (yAlign === 'center') {
      left -= space;
    } else {
      left += space;
    }
  } else if (xAlign === 'center') {
    left += width / 2 + space * 2;
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';

  // // Auto position tooltip, based on dimensions and boundary
  // const chartRect = chart.canvas.getBoundingClientRect();
  // const tooltipRect = tooltipEl.getBoundingClientRect();
  // if (tooltipRect.x + tooltipRect.width > chartRect.width) {
  //   console.log('Out of bounds');
  // }
};

export const fetchUserParams = async () => {
  const cookies = new Cookies();
  const tokens = cookies.get('tokens');

  const unitMap: any = {
    'Air Pressure': 'mbar',
    CDOM: 'ppb',
    Chlorophyll: 'RFU',
    Nitrate: 'μmol/L',
    'Dissolved Oxygen': 'μmol/L',
    'Crude Oil': 'ppb',
    pH: 'pH',
    Phycoerythrin: 'μg/L',
    'Refined Fuels': 'ppm',
    'Relative Humidity': '%',
    Salinity: 'PSU',
    'Solar Irradiance': 'W/m²',
    'Water Temperature': 'degC',
    'Air Temperature': 'degC',
    Turbidity: 'NTU',
    'Wind Speed': 'm/s',
    pCO2: 'ppm',
    'Secchi depth': 'm',
    'Total alkalinity': 'µeq/kg',
    TSS: 'mg/L',
    DIC: 'µmol/kg',
    NOx: 'µmol/l',
    Phosphate: 'µmol/l',
    Silicate: 'µmol/l',
    Pb: 'pmol/kg',
    Zn: 'nmol/kg',
    Cu: 'nmol/kg',
    Cd: 'nmol/kg',
    'Current Velocity': 'cm/s',
    'Current Direction': '°'
  };

  if (tokens) {
    const decoded: any = jwt_decode(tokens.accessToken);
    const params = await api.get(`/api/accesibleParameters/${decoded.userId}`);
    return params.data.map((param: any) => ({
      ...param,
      unit: unitMap[param.parameterDesc]
    }));
  }
  return {};
};

export const getQuantity = (quantity: any): string => {
  return quantity.toLowerCase().replace(/\s/g, '-');
};

export const truncateMarkdown = (markdown: any, maxLength: any) => {
  if (markdown.length <= maxLength) return markdown;

  // Find the last space within the maxLength
  const truncated = markdown.slice(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  // Cut off at the last space and add ellipsis
  return `${truncated.slice(0, lastSpaceIndex)}...`;
};

export const getNearestInfluxDBInterval = (
  fromTimestamp: number,
  toTimestamp: number
): any => {
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const durationInDays = (toTimestamp - fromTimestamp) / millisecondsInDay;

  let nearestInterval = null;

  if (durationInDays <= 1) {
    nearestInterval = '5m';
  } else if (durationInDays > 1 && durationInDays <= 3) {
    nearestInterval = '30m';
  } else if (durationInDays > 3 && durationInDays <= 7) {
    nearestInterval = '1h';
  } else if (durationInDays > 7 && durationInDays <= 50) {
    nearestInterval = '12h';
  } else if (durationInDays > 50 && durationInDays <= 100) {
    nearestInterval = '1d';
  } else if (durationInDays > 100 && durationInDays <= 300) {
    nearestInterval = '3d';
  } else if (durationInDays > 300 && durationInDays <= 800) {
    nearestInterval = '1w';
  } else {
    nearestInterval = '1mo';
  }

  return nearestInterval;
};

export const queryData = async (
  startTime: any,
  endTime: any,
  card: any,
  interval: string,
  dontShowGlobalLoader: boolean = true,
  url: string
) => {
  const config: any = {
    method: 'post',
    url: `${import.meta.env.VITE_API_URL}${url}`,
    data: {
      parameterName: card.parameterName,
      before: endTime,
      after: startTime,
      parameterId: card.id.toString(),
      depth: card.depth.toUpperCase().replace(/ /g, '_'),
      ...(card.isCruiseParam
        ? {
            location: card.location
          }
        : card.isMonthlyAvg
        ? {
            location: card.location
          }
        : {
            location: card.location,
            interval: interval
          })
    },
    accuracy: card.noOfDecimalPlaces,
    dontShowGlobalLoader
  };
  const response: any = await api(config);
  // @ts-ignore
  return {
    data: response.data,
    accuracy: response.config.accuracy,
    isCruiseParam: card.isCruiseParam
  };
};

export const useFetchGraphData = () => {
  const dispatch = useDispatch();

  const fetchGraphData = useCallback(
    async (
      startTime: number,
      endTime: number,
      cardSet: any[],
      isZoomCall = false
    ) => {
      let fetchedInitialData = false;
      try {
        dispatch(setIsLoading(true));

        const interval = getNearestInfluxDBInterval(startTime, endTime);
        const newData = await cardSet.reduce(
          async (tempData: any, card: any) => {
            const res = await queryData(
              startTime,
              endTime,
              card,
              interval,
              false,
              '/api/aggData/line'
            );
            const temp = await tempData;
            temp.push(res);
            return temp;
          },
          []
        );

        const nextParams = cardSet.map((card: any) => card.parameterName);

        dispatch(setInterval(interval));
        dispatch(setGraphData({ data: newData, nextParams }));
        dispatch(setActiveGraph('Line Chart'));
        dispatch(setPrevEndTime(endTime));
        dispatch(setPrevStartTime(startTime));
      } catch (error) {
        console.error('Error fetching graph data:', error);
      } finally {
        dispatch(setIsLoading(false));
        fetchedInitialData = true;
      }

      if (!isZoomCall && fetchedInitialData) {
        try {
          const intervals = getNearestBinSizesForBoxPlot(startTime, endTime);

          const newData = await cardSet.reduce(
            async (tempData: any, card: any) => {
              const cardBoxData: any = {};
              for (const interval of intervals) {
                const res: any = await queryData(
                  startTime,
                  endTime,
                  card,
                  interval.toLowerCase(),
                  true,
                  '/api/aggData/box'
                );
                cardBoxData[interval] = processBoxData(
                  card.isCruiseParam,
                  res,
                  interval
                );
              }
              const temp = await tempData;
              temp.push(cardBoxData);
              return temp;
            },
            []
          );
          dispatch(setBoxData(newData));
        } catch (error) {
          console.error('Error fetching box data:', error);
        }
      }

      if (!isZoomCall && fetchedInitialData) {
        try {
          const newData = await cardSet.reduce(
            async (tempData: any, card: any) => {
              const res = await queryData(
                startTime,
                endTime,
                card,
                '1d',
                true,
                '/api/aggData/line'
              );
              const temp = await tempData;
              temp.push(res);
              return temp;
            },
            []
          );
          dispatch(setScatterData(newData));
        } catch (error) {
          console.error('Error fetching scatter data:', error);
        }
      }
    },
    [dispatch]
  );

  return { fetchGraphData };
};

export const getNearestBinSizesForBoxPlot = (
  fromTimestamp: number,
  toTimestamp: number
): any => {
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const durationInDays = (toTimestamp - fromTimestamp) / millisecondsInDay;

  let intervals;

  if (durationInDays <= 30) {
    intervals = ['1D', '1W', '1MO'];
  } else if (durationInDays > 30 && durationInDays <= 210) {
    intervals = ['1W', '1MO'];
  } else {
    intervals = ['1MO'];
  }

  return intervals;
};
