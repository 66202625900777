import React from 'react';
import { If, Then, Else } from 'react-if';
import { isEmpty } from 'lodash';

import { useQuery } from 'react-query';
import cms from 'utils/cms';
import useViewportRatio from 'utils/useViewportRatio';
import useWidth from 'utils/useWidth';

import custom_palettes from 'theme/custom_palettes';
import PageTitle from 'components/text/PageTitle';
import Container from 'components/common/Container';
import ReactMarkdown from 'react-markdown';
import WaveLine from 'components/waveLine/WaveLine';

// =============================================================================
export default function OurWatersPage() {
  const {
    isLoading,
    isError,
    data: query
  } = useQuery('our-water', () =>
    cms.get('/api/our-water?populate=deep').then((res) => res.data)
  );
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const width = useWidth();
  const ratio = useViewportRatio(standard);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { title, sectionList } = data;

  return (
    <div>
      <Container>
        <PageTitle text={title} />
      </Container>
      <If condition={!isEmpty(sectionList)}>
        <Then>
          {sectionList.map((section: any, index: any) => {
            const isEven = (index + 1) % 2 == 0;
            const isLastElement = index === sectionList.length - 1;
            // console.log(section, index, isLastElement);
            return (
              <div key={index}>
                <div
                  style={{
                    backgroundColor: isEven
                      ? custom_palettes.green[50]
                      : custom_palettes.white['main'],
                    paddingTop: 50
                  }}
                >
                  <Container
                    style={{
                      display: 'flex',
                      flexDirection:
                        width === 'mobile'
                          ? 'column'
                          : isEven
                          ? 'row'
                          : 'row-reverse'
                    }}
                  >
                    <div
                      style={{
                        width: width === 'mobile' ? '100%' : '45%',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <ReactMarkdown className='markdown'>
                        {section.text}
                      </ReactMarkdown>
                    </div>

                    {width === 'mobile' ? (
                      <div style={{ height: 56 }} />
                    ) : (
                      <div style={{ width: '10%' }} />
                    )}

                    <If condition={!isEmpty(section.gif)}>
                      <Then>
                        {section.gif.data.attributes.mime.includes('video') ? (
                          <div
                            style={{
                              width: width === 'mobile' ? '100%' : '45%'
                            }}
                          >
                            <video
                              className='ourWatersGif'
                              style={
                                width === 'mobile'
                                  ? {
                                      width: '100%',
                                      height: 'auto',
                                      maxHeight: 350
                                    }
                                  : {
                                      maxHeight: 350,
                                      width: '100%',
                                      height: 'auto'
                                    }
                              }
                              loop
                              autoPlay
                              muted
                            >
                              <source
                                src={
                                  import.meta.env.VITE_CMS_URL +
                                  section.gif.data.attributes.url
                                }
                                type='video/mp4'
                              />
                            </video>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: width === 'mobile' ? '100%' : '45%'
                            }}
                          >
                            <img
                              src={
                                import.meta.env.VITE_CMS_URL +
                                section.gif.data?.attributes?.url
                              }
                              alt={
                                section.gif.data?.attributes?.alternativeText
                              }
                              style={
                                width === 'mobile'
                                  ? {
                                      width: '100%',
                                      height: 'auto',
                                      maxHeight: 350
                                    }
                                  : {
                                      maxHeight: 350,
                                      width: '100%',
                                      height: 'auto'
                                    }
                              }
                            />
                          </div>
                        )}
                      </Then>
                      <Else>
                        <div
                          style={{
                            width: 200,
                            height: 200
                          }}
                        />
                      </Else>
                    </If>
                  </Container>

                  <If condition={!isLastElement}>
                    <Then>
                      <div
                        style={{
                          backgroundColor: isEven
                            ? custom_palettes.green[50]
                            : custom_palettes.white.main
                        }}
                      >
                        <WaveLine
                          style={{
                            fill: isEven
                              ? custom_palettes.white.main
                              : custom_palettes.green[50]
                          }}
                        />
                      </div>
                    </Then>
                    <Else>
                      <div
                        style={{
                          backgroundColor: isEven
                            ? custom_palettes.green[50]
                            : custom_palettes.white['main'],
                          height: 80
                        }}
                      />
                    </Else>
                  </If>
                </div>
              </div>
            );
          })}
        </Then>
      </If>
    </div>
  );
}
