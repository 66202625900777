import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import Typography from '@mui/material/Typography';

import Container from 'components/common/Container';
import HeaderTab from 'components/detailCard/HeaderTab';
import TabPanel from 'components/detailCard/TabPanel';
import api from 'utils/api';

import { useAppDispatch } from 'redux/hooks';
import {
  updateActivePoint,
  transformToPoint
} from 'redux/slice/mapControllerSlice';
import SvgIcon from '@mui/material/SvgIcon';

// =============================================================================
import { ReactComponent as Cloud } from 'static/icons/Cloud.svg';
import { ReactComponent as Wind } from 'static/icons/Wind.svg';
import { ReactComponent as Water } from 'static/icons/Water.svg';
import { ReactComponent as Salinity } from 'static/icons/Salinity.svg';
import { ReactComponent as Sun } from 'static/icons/Sun.svg';

// =============================================================================
export const cardTransforms = [
  'scale(2.5) translate(0%, -20%)',
  'scale(2.5) translate(25%, -30%)',
  'scale(2.5) translate(-30%, 40%)'
];

// =============================================================================
interface DetailCardProps {
  cardData: {};
  setOverline: any;
  disabled: boolean[];
}

export default function DetailCard(props: DetailCardProps) {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);

  const headers = Object.keys(props.cardData);
  const bodies = Object.values(props.cardData);

  return (
    <div>
      <HeaderTab
        headers={headers}
        value={value}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <div style={{ height: 32 }} />
      {generateTabPanels(bodies)}
    </div>
  );

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
    dispatch(updateActivePoint(headers[newValue]));
    dispatch(transformToPoint(cardTransforms[newValue]));
  }

  function generateTabPanels(cards: any) {
    return cards.map((obj: any, index: number) => {
      return (
        <TabPanel value={value} index={index} key={index}>
          <Container>
            <Typography variant='body_large_strong' color='blue.900'>
              {obj.title}
            </Typography>
            <div style={{ height: 8 }} />
            <Typography variant='body_regular' color='blue.900'>
              {obj.content}
            </Typography>
          </Container>
          {obj.data && obj.data.length > 0 ? (
            WhiteCardSection(obj.data)
          ) : (
            <div />
          )}
        </TabPanel>
      );
    });
  }
}

// =============================================================================

const WhiteCardSection = (data: any) => {
  return (
    <div
      style={{
        display: 'flex',
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
      }}
    >
      <WhiteCard icon={<Wind />} title='Wind' content={`${data[0]}`} />
      <WhiteCard
        icon={<Water />}
        title='Water Temperature'
        content={`${data[1]}`}
      />
      <WhiteCard
        icon={<Cloud />}
        title='Air Temperature'
        content={`${data[2]}`}
      />
      <WhiteCard icon={<Cloud />} title='Air Pressure' content={`${data[3]}`} />
      <WhiteCard icon={<Salinity />} title='Salinity' content={`${data[4]}`} />
      <WhiteCard
        icon={<Sun />}
        title='Solar IRRADIANCE'
        content={`${data[5]}`}
      />
    </div>
  );
};

// =============================================================================
interface WhiteCardProps {
  icon: React.ReactNode;
  title: string;
  content?: string;
}

const WhiteCard = (props: WhiteCardProps) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        minWidth: 100,
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        margin: 16,
        padding: 16,
        borderRadius: 8
      }}
    >
      <SvgIcon>{props.icon}</SvgIcon>
      <div style={{ height: 8 }} />
      <Typography variant='caption' color='blue.900'>
        {props.title}
      </Typography>
      <div style={{ height: 8 }} />
      <Typography variant='body_regular_strong' color='blue.900'>
        {props.content}
      </Typography>
    </div>
  );
};
