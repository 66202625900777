import React from 'react';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

// =============================================================================
import { ReactComponent as Cloud } from 'static/icons/Cloud.svg';
import { ReactComponent as Wind } from 'static/icons/Wind.svg';
import { ReactComponent as Water } from 'static/icons/Water.svg';
import { ReactComponent as Salinity } from 'static/icons/Salinity.svg';
import { ReactComponent as Sun } from 'static/icons/Sun.svg';
import { getWindDirection } from 'pages/Home/Graph/utils';

// =============================================================================

// =============================================================================
const wrapper_style = {
  margin: '0 24px 0 24px'
};

const data_style = {
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center'
};

// =============================================================================
interface DataProps {
  icon: React.ReactNode;
  title: string;
  content: string;
}

const Data = (props: DataProps) => {
  return (
    <div style={data_style}>
      <SvgIcon>{props.icon}</SvgIcon>
      <div style={{ width: 12 }} />
      <Typography variant='overline' color='gray.700' sx={{ flexGrow: 1 }}>
        {props.title}
      </Typography>
      <Typography
        variant='body_regular_strong'
        style={{
          textAlign: 'right'
        }}
        color='blue.900'
      >
        {props.content}
      </Typography>
    </div>
  );
};

export default function BuoyInfo(props: any) {
  return (
    <div style={wrapper_style}>
      <Data icon={<Wind />} title='Wind' content={props.infos[0]} />
      <Data
        icon={<Water />}
        title='Water Temperature'
        content={props.infos[1]}
      />
      <Data icon={<Cloud />} title='Air Temperature' content={props.infos[2]} />
      <Data icon={<Cloud />} title='Air Pressure' content={props.infos[3]} />
      <Data icon={<Salinity />} title='Salinity' content={props.infos[4]} />
      <Data icon={<Sun />} title='Solar IRRADIANCE' content={props.infos[5]} />
    </div>
  );
}
