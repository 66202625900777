import React from 'react';
import { Box } from '@mui/material';

type PropType = {
  announcement: string | null;
  expireOn: string | null;
};

const Announcement = (prop: PropType) => {
  const announcementExist = !!prop.announcement;
  const announcementExpire =
    prop.expireOn && Date.now() > new Date(prop.expireOn).getTime();
  if (!announcementExist || announcementExpire) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: 'blue.800',
        color: 'primary.contrastText',
        padding: '8px 0',
        fontSize: '1rem'
      }}
    >
      <Box
        sx={{
          color: 'primary.contrast',
          display: 'inline-block',
          paddingLeft: '100%',
          animation: 'scrollText 25s linear infinite',
          '@keyframes scrollText': {
            '0%': { transform: 'translateX(0%)' },
            '100%': { transform: 'translateX(-100%)' }
          }
        }}
      >
        {prop.announcement}
      </Box>
    </Box>
  );
};

export default Announcement;
