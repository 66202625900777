import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Container from 'components/common/Container';
import HeaderTab from 'components/detailCard/HeaderTab';
import TabPanel from 'components/detailCard/TabPanel';
import MiniMap from 'components/map/MiniMap';
import PictureBody from 'components/text/PictureBody';
import HeadingBody from 'components/text/HeadingBody';
import DisplayHeadingBody from 'components/text/DisplayHeadingBody';
import { useAppDispatch } from 'redux/hooks';
import { updateActivePoint } from 'redux/slice/mapControllerSlice';
import useWidth from 'utils/useWidth';
import custom_palettes from 'theme/custom_palettes';
import useViewportRatio from 'utils/useViewportRatio';
import ReactMarkdown from 'react-markdown';
import WaveLine from 'components/waveLine/WaveLine';

// =============================================================================
interface StationProps {
  title: string;
  description: string;
  stationList: any[];
}

export default function Station(props: StationProps) {
  const width = useWidth();
  const [value, setValue] = React.useState(0);
  const dispatch = useAppDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(updateActivePoint(props.stationList[newValue].acronym));
  };

  return (
    <div
      style={{
        position: 'relative' as const
      }}
    >
      {width === 'mobile' ? (
        <div style={{ backgroundColor: custom_palettes.blue[800] }}>
          <Container style={{ textAlign: 'center' }}>
            <div style={{ height: 32 }} />
            <Typography variant='h3' color='white.main'>
              {props.title}
            </Typography>
            <div style={{ height: 16 }} />
            <Typography variant='body_regular' color='white.main'>
              {props.description}
            </Typography>
          </Container>
        </div>
      ) : (
        <Container>
          <HeadingBody
            title={props.title}
            titleVariant='h2'
            text={props.description}
            textVariant='body_large'
            textWidth={'100%'}
            style={{
              textAlign: 'center'
            }}
          />
        </Container>
      )}
      <div
        style={{
          backgroundColor:
            width === 'mobile' ? custom_palettes.blue[800] : undefined
        }}
      >
        {width === 'mobile' ? (
          <div style={{ height: 24 }} />
        ) : (
          <div style={{ height: 56 }} />
        )}
        <HeaderTab
          mobile={width === 'mobile'}
          value={value}
          onChange={handleChange}
          headers={props.stationList.map((station: any) =>
            width === 'mobile' ? station.acronym : station.title
          )}
        />
      </div>
      <Container style={{ marginTop: 24 }}>
        <div>
          <Grid container>
            <Grid item xs={width === 'mobile' ? 12 : 6}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: width === 'mobile' ? 48 : undefined
                }}
              >
                <MiniMap />
              </div>
            </Grid>
            <Grid item xs={width === 'mobile' ? 12 : 6}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {generateStationList()}
              </div>
            </Grid>
          </Grid>
        </div>

        <div style={{ height: width === 'mobile' ? 32 : 64 }} />

        {generateImageList()}
      </Container>
      <div style={{ backgroundColor: custom_palettes.white.main }}>
        <WaveLine style={{ fill: custom_palettes.white.main }} />
      </div>
    </div>
  );

  function generateStationList() {
    return props.stationList.map((station: any, index: number) => (
      <TabPanel key={index} index={index} value={value}>
        <DisplayHeadingBody
          display={width === 'mobile' ? undefined : station.acronym}
          title={station.title}
          titleVariant='h3'
          titleWidth={414}
          text={station.description}
          textVariant={width === 'mobile' ? 'body_regualr' : 'body_large'}
          textWidth={414}
          style={{ textAlign: 'center' }}
        />
      </TabPanel>
    ));
  }

  function generateImageList() {
    const IMAGE_URL = import.meta.env.VITE_CMS_URL;
    return props.stationList.map((station: any, index: number) => (
      <TabPanel key={index} index={index} value={value}>
        <Grid
          container
          rowSpacing={10}
          columnSpacing={width === 'mobile' ? '' : 10}
        >
          {station.imageList.map((image: any, index: number) => (
            <Grid key={index} item xs={width === 'mobile' ? 12 : 6}>
              <PictureBody
                key={index}
                imageSource={IMAGE_URL + image.image?.data?.attributes.url} // Prevent image not existing from crashing
                imageAlt={'Image not available'} // Image already have description
                text={image.description}
                textVariant='body_regular'
                imageStyle={{
                  width: 'auto',
                  maxHeight: 300
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <ReactMarkdown className='markdown'>{station.text}</ReactMarkdown>
          </Grid>
        </Grid>
      </TabPanel>
    ));
  }
}
