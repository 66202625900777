import React from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { If, Then, Else } from 'react-if';
import ImageLoad from 'components/ImageLoad';
import { isEmpty } from 'lodash';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import custom_palettes from 'theme/custom_palettes';
import useWidth from 'utils/useWidth';
import useCms from 'utils/useCms';
import { getAttributes } from 'utils/queryFilter';
import { useNavigate } from 'react-router-dom';
// =============================================================================
interface ArticleProps {
  articleId: string | undefined;
  mobile?: boolean;
}

export default function Articles(props: ArticleProps) {
  const { isLoading, query } = useCms(
    `article-${props.articleId}`,
    'articles',
    [`filters[id][$eq]=${props.articleId}`]
  );
  const width = useWidth();
  const navigate = useNavigate();

  let content: any = {};
  if (!isLoading) {
    content = getAttributes(query)[0];
    if (!content) return <div>No article found</div>;
  }

  const handleTagSearch = (e: any) => {
    const tag = e.target.textContent.replace('#', '').toUpperCase();
    navigate(`/highlights/tag/${tag}`);
  };

  return (
    <If condition={!isEmpty(content)}>
      <Then>
        <div>
          <div style={{ background: custom_palettes.blue[50] }}>
            <Container style={{ padding: 0 }}>
              <div style={{ height: 32 }} />

              <div style={{ margin: 'auto' }}>
                <Typography
                  variant='caption'
                  color='gray.800'
                  sx={{ paddingLeft: '10px' }}
                >
                  Last updated on {moment(content.updatedAt).format('ll')}
                </Typography>
                <div style={{ height: 24 }} />

                <If
                  condition={content?.headerImageUrl?.length > 0 ? true : false}
                >
                  <Then>
                    <CarouselProvider
                      naturalSlideWidth={10}
                      naturalSlideHeight={15}
                      totalSlides={content?.headerImageUrl?.length}
                      isPlaying
                      interval={5000}
                      infinite
                    >
                      <div style={{ position: 'relative' }}>
                        <Slider
                          style={{
                            height: window.innerWidth / 2,
                            maxHeight: 600,
                            margin: 'auto'
                          }}
                        >
                          {generateImages()}
                        </Slider>
                      </div>
                    </CarouselProvider>
                  </Then>
                </If>
              </div>
            </Container>
          </div>

          <div style={{ height: 24 }} />

          {width === 'mobile' || width === 'tablet' ? (
            <Container sx={{ paddingLeft: '40px', paddingRight: '40px' }}>
              <Typography variant='h2' color='blue.900'>
                {content.title}
              </Typography>

              <Typography variant='subtitle' color='blue.900'>
                {content.subtitle}
              </Typography>

              <If condition={content.tag}>
                <Then>
                  <div style={{ height: 16 }} />

                  <Typography variant='overline' color='gray.800'>
                    {content?.tag?.split(' #').map((t: any, i: any) => (
                      <span
                        style={{
                          cursor: 'pointer',
                          marginRight: 10,
                          color: '#256F94'
                        }}
                        key={i}
                        onClick={handleTagSearch}
                      >
                        {`${i == 0 ? '' : '#'}${t}`}
                      </span>
                    ))}
                  </Typography>
                </Then>
              </If>

              <If condition={content.body}>
                <Then>
                  <ReactMarkdown className='markdown'>
                    {content.body}
                  </ReactMarkdown>
                </Then>
              </If>

              <If condition={content.highlight}>
                <Then>
                  <div
                    style={{
                      borderTop: '1px solid #0D3650',
                      borderBottom: '1px solid #0D3650',
                      padding: '20px 0',
                      marginTop: '30px'
                    }}
                  >
                    <Typography
                      variant='h5'
                      color='blue.900'
                      sx={{ fontWeight: 'bold' }}
                    >
                      {content.highlight}
                    </Typography>
                  </div>
                </Then>
              </If>

              <If condition={!isEmpty(content?.bodyImage1)}>
                <Then>
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <ImageLoad
                      url={content?.bodyImage1?.url}
                      innerStyle={{
                        width: '100%',
                        maxHeight: 400,
                        objectFit: 'cover'
                      }}
                    />
                    <If condition={!isEmpty(content?.bodyImage1?.caption)}>
                      <Then>
                        <div
                          style={{
                            marginTop: '10px',
                            opacity: '0.5'
                          }}
                        >
                          {content?.bodyImage1?.caption}
                        </div>
                      </Then>
                    </If>
                  </div>
                </Then>
              </If>

              <If condition={content.bodyText1}>
                <Then>
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <ReactMarkdown className='markdown'>
                      {content.bodyText1}
                    </ReactMarkdown>
                  </div>
                </Then>
              </If>

              <If condition={content.bodyHighlight1}>
                <Then>
                  <div
                    style={{
                      borderTop: '1px solid #0D3650',
                      borderBottom: '1px solid #0D3650',
                      padding: '20px 0',
                      marginTop: '30px'
                    }}
                  >
                    <Typography
                      variant='h5'
                      color='blue.900'
                      sx={{ fontWeight: 'bold' }}
                    >
                      {content.bodyHighlight1}
                    </Typography>
                  </div>
                </Then>
              </If>

              <If condition={!isEmpty(content?.bodyImage2)}>
                <Then>
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <ImageLoad
                      url={content?.bodyImage2?.url}
                      innerStyle={{
                        width: '100%',
                        maxHeight: 400,
                        objectFit: 'cover'
                      }}
                    />
                    <If condition={!isEmpty(content?.bodyImage2?.caption)}>
                      <Then>
                        <div
                          style={{
                            marginTop: '10px',
                            opacity: '0.5'
                          }}
                        >
                          {content?.bodyImage2?.caption}
                        </div>
                      </Then>
                    </If>
                  </div>
                </Then>
              </If>

              <If condition={content.bodyText2}>
                <Then>
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <ReactMarkdown className='markdown'>
                      {content.bodyText2}
                    </ReactMarkdown>
                  </div>
                </Then>
              </If>

              <If condition={content.bodyHighlight2}>
                <Then>
                  <div
                    style={{
                      borderTop: '1px solid #0D3650',
                      borderBottom: '1px solid #0D3650',
                      padding: '20px 0',
                      marginTop: '30px'
                    }}
                  >
                    <Typography
                      variant='h5'
                      color='blue.900'
                      sx={{ fontWeight: 'bold' }}
                    >
                      {content.bodyHighlight2}
                    </Typography>
                  </div>
                </Then>
              </If>

              <If condition={!isEmpty(content?.bodyImage3)}>
                <Then>
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <ImageLoad
                      url={content?.bodyImage3?.url}
                      innerStyle={{
                        width: '100%',
                        maxHeight: 400,
                        objectFit: 'cover'
                      }}
                    />
                    <If condition={!isEmpty(content?.bodyImage3?.caption)}>
                      <Then>
                        <div
                          style={{
                            marginTop: '10px',
                            opacity: '0.5'
                          }}
                        >
                          {content?.bodyImage3?.caption}
                        </div>
                      </Then>
                    </If>
                  </div>
                </Then>
              </If>

              <If condition={content.bodyText3}>
                <Then>
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <ReactMarkdown className='markdown'>
                      {content.bodyText3}
                    </ReactMarkdown>
                  </div>
                </Then>
              </If>

              <If condition={content.bodyHighlight3}>
                <Then>
                  <div
                    style={{
                      borderTop: '1px solid #0D3650',
                      borderBottom: '1px solid #0D3650',
                      padding: '20px 0',
                      marginTop: '30px'
                    }}
                  >
                    <Typography
                      variant='h5'
                      color='blue.900'
                      sx={{ fontWeight: 'bold' }}
                    >
                      {content.bodyHighlight3}
                    </Typography>
                  </div>
                </Then>
              </If>

              <If condition={content.footer}>
                <Then>
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <ReactMarkdown className='markdown'>
                      {content.footer}
                    </ReactMarkdown>
                  </div>
                </Then>
              </If>

              <Typography variant='overline' color='gray.800'>
                Published by {content.author}
                <br />
                {moment(content.publishedAt).format('ll')}
                <br />
              </Typography>
            </Container>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Container
                sx={{ maxWidth: '900px', marginLeft: 0, marginRight: 0 }}
              >
                <Typography variant='h2' color='blue.900'>
                  {content.title}
                </Typography>

                <Typography variant='subtitle' color='blue.900'>
                  {content.subtitle}
                </Typography>

                <div style={{ height: 16 }} />

                <If condition={content.body}>
                  <Then>
                    <ReactMarkdown className='markdown'>
                      {content.body}
                    </ReactMarkdown>
                  </Then>
                </If>

                <If condition={content.highlight}>
                  <Then>
                    <div
                      style={{
                        borderTop: '1px solid #0D3650',
                        borderBottom: '1px solid #0D3650',
                        padding: '20px 0',
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        variant='h5'
                        color='blue.900'
                        sx={{ fontWeight: 'bold' }}
                      >
                        {content.highlight}
                      </Typography>
                    </div>
                  </Then>
                </If>

                <If condition={!isEmpty(content?.bodyImage1)}>
                  <Then>
                    <div
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <ImageLoad
                        url={content?.bodyImage1?.url}
                        innerStyle={{
                          width: '100%',
                          maxHeight: 400,
                          objectFit: 'cover'
                        }}
                      />
                      <If condition={!isEmpty(content?.bodyImage1?.caption)}>
                        <Then>
                          <div
                            style={{
                              marginTop: '10px',
                              opacity: '0.5'
                            }}
                          >
                            {content?.bodyImage1?.caption}
                          </div>
                        </Then>
                      </If>
                    </div>
                  </Then>
                </If>

                <If condition={content.bodyText1}>
                  <Then>
                    <div
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <ReactMarkdown className='markdown'>
                        {content.bodyText1}
                      </ReactMarkdown>
                    </div>
                  </Then>
                </If>

                <If condition={content.bodyHighlight1}>
                  <Then>
                    <div
                      style={{
                        borderTop: '1px solid #0D3650',
                        borderBottom: '1px solid #0D3650',
                        padding: '20px 0',
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        variant='h5'
                        color='blue.900'
                        sx={{ fontWeight: 'bold' }}
                      >
                        {content.bodyHighlight1}
                      </Typography>
                    </div>
                  </Then>
                </If>

                <If condition={!isEmpty(content?.bodyImage2)}>
                  <Then>
                    <div
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <ImageLoad
                        url={content?.bodyImage2?.url}
                        innerStyle={{
                          width: '100%',
                          maxHeight: 400,

                          objectFit: 'cover'
                        }}
                      />
                      <If condition={!isEmpty(content?.bodyImage2?.caption)}>
                        <Then>
                          <div
                            style={{
                              marginTop: '10px',
                              opacity: '0.5'
                            }}
                          >
                            {content?.bodyImage2?.caption}
                          </div>
                        </Then>
                      </If>
                    </div>
                  </Then>
                </If>

                <If condition={content.bodyText2}>
                  <Then>
                    <div
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <ReactMarkdown className='markdown'>
                        {content.bodyText2}
                      </ReactMarkdown>
                    </div>
                  </Then>
                </If>

                <If condition={content.bodyHighlight2}>
                  <Then>
                    <div
                      style={{
                        borderTop: '1px solid #0D3650',
                        borderBottom: '1px solid #0D3650',
                        padding: '20px 0',
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        variant='h5'
                        color='blue.900'
                        sx={{ fontWeight: 'bold' }}
                      >
                        {content.bodyHighlight2}
                      </Typography>
                    </div>
                  </Then>
                </If>

                <If condition={!isEmpty(content?.bodyImage3)}>
                  <Then>
                    <div
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <ImageLoad
                        url={content?.bodyImage3?.url}
                        innerStyle={{
                          width: '100%',
                          maxHeight: 400,
                          objectFit: 'cover'
                        }}
                      />
                      <If condition={!isEmpty(content?.bodyImage3?.caption)}>
                        <Then>
                          <div
                            style={{
                              marginTop: '10px',
                              opacity: '0.5'
                            }}
                          >
                            {content?.bodyImage3?.caption}
                          </div>
                        </Then>
                      </If>
                    </div>
                  </Then>
                </If>

                <If condition={content.bodyText3}>
                  <Then>
                    <div
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <ReactMarkdown className='markdown'>
                        {content.bodyText3}
                      </ReactMarkdown>
                    </div>
                  </Then>
                </If>

                <If condition={content.bodyHighlight3}>
                  <Then>
                    <div
                      style={{
                        borderTop: '1px solid #0D3650',
                        borderBottom: '1px solid #0D3650',
                        padding: '20px 0',
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        variant='h5'
                        color='blue.900'
                        sx={{ fontWeight: 'bold' }}
                      >
                        {content.bodyHighlight3}
                      </Typography>
                    </div>
                  </Then>
                </If>

                <If condition={content.footer}>
                  <Then>
                    <div
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <ReactMarkdown className='markdown'>
                        {content.footer}
                      </ReactMarkdown>
                    </div>
                  </Then>
                </If>

                <Typography variant='overline' color='gray.800'>
                  Published by {content.author}
                  <br />
                  {moment(content.publishedAt).format('ll')}
                  <br />
                </Typography>
              </Container>

              <If condition={content.tag}>
                <Then>
                  <div
                    style={{
                      background: custom_palettes.blue[50],
                      marginLeft: '10px',
                      alignSelf: 'flex-start',
                      padding: '20px',
                      borderRadius: '10px'
                    }}
                  >
                    <Typography variant='overline' color='gray.800'>
                      {content?.tag?.split(' #').map((t: any, i: any) => (
                        <div
                          style={{
                            paddingTop: '5px',
                            paddingBottom: '5px'
                          }}
                          key={i}
                        >
                          <span
                            style={{
                              cursor: 'pointer',
                              color: '#256F94'
                            }}
                            onClick={handleTagSearch}
                          >{`${i == 0 ? '' : '#'}${t}`}</span>
                        </div>
                      ))}
                    </Typography>
                  </div>
                </Then>
              </If>
            </div>
          )}

          <div style={{ height: 160 }} />
        </div>
      </Then>
    </If>
  );

  function generateImages() {
    if (content.headerImageUrl)
      return content.headerImageUrl.map((url: any, index: number) => (
        <Slide index={index} key={uuid()}>
          <ImageLoad
            url={url}
            innerStyle={{
              width: '100%',
              maxHeight: 600,
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </Slide>
      ));
    else return null;
  }
}
